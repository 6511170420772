import React from 'react';
import { useState, useEffect } from 'react';
import Api from '../../services/api';
import { useHistory, Link } from 'react-router-dom';
import logoHorizontal from '../../assets/logohocohorizontal.png';
import backgroundMenu from '../../assets/backgroundMenu.jpg';
import { getPerfil, getData, logout } from '../../services/auth';
import {
  MDBNavbar,
  MDBNavbarNav,
  MDBNavItem,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBModalFooter,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
  MDBIcon,
  MDBSideNavCat,
  MDBSideNavNav,
  MDBSideNav,
  MDBContainer,
  MDBSideNavLink,
} from 'mdbreact';

import api from '../../services/api';
export default function NavBar(props) {
  const [alertas, setAlertas] = useState([]);
  const [toggleStateA, setToggleStateA] = useState(false);
  const [breakWidth, setBreakWidth] = useState(1300);
  const [windowWidth, setWindowWidth] = useState(0);
  const [modulos, seModulos] = useState([]);
  const history = useHistory();
  const [isOpen, setIsOpen] = useState(false);
  const handleResize = () => setWindowWidth(window.innerWidth);
  const handleToggleClickA = () => setToggleStateA(!toggleStateA);
  const buscaAlertas = async () => {
    const result = await api.get('/alertas');

    setAlertas(result.data);
  };

  useEffect(() => {
    buscaAlertas();
  }, []);
  const acesso = async () => {
    try {
      const response = await Api.get('/card-acesso');
      const teste = window.location.pathname.split('/');

      seModulos(response.data);
    } catch (error) {
      logout();
      // this.props.history.push('/app');
    }
  };

  const navStyle = {
    paddingLeft: windowWidth > breakWidth ? '190px' : '16px',
  };

  const mainStyle = {
    // margin: '0 6%',
    margin: '0 1%',
    paddingTop: '5.5rem',
    paddingLeft: windowWidth > breakWidth ? '210px' : '0',
  };

  const specialCaseNavbarStyles = {
    WebkitBoxOrient: 'horizontal',
    flexDirection: 'row',
  };

  const sair = () => {
    logout();
    history.push('/');

    // return <Redirect to={'/app'} />;
  };

  useEffect(() => {
    handleResize();
    acesso();
    window.addEventListener('resize', () => handleResize());

    return window.removeEventListener('resize', () => handleResize());
  }, []);

  return (
    <div className="fixed-sn white-skin">
      <MDBSideNav
        triggerOpening={toggleStateA}
        breakWidth={breakWidth}
        bg={backgroundMenu}
        mask="strong"
        fixed
      >
        <div className="my-3 w-100 d-flex justify-content-center  d-print-none">
          <Link to="/app" className="w-100 h-100">
            <img
              src={logoHorizontal}
              alt="Logo"
              style={{
                width: '190px',

                borderRadius: '5px',
              }}
            />
          </Link>
        </div>

        <br />
        <div className="d-flex justify-content-center blue-grey-text font-weight-bolder">
          Olá, {getPerfil().nome}
        </div>
        <MDBSideNavNav>
          {modulos.map(moduloMenu => (
            <MDBSideNavCat
              key={moduloMenu.id}
              name={moduloMenu.modulo.titulo}
              id="submit-blog-cat"
              icon="chevron-right"
            >
              {moduloMenu.modulo.funcionalidades.map(func => (
                <MDBSideNavLink key={func.id} to={func.rota}>
                  {func.nome}
                </MDBSideNavLink>
              ))}
            </MDBSideNavCat>
          ))}
        </MDBSideNavNav>
      </MDBSideNav>

      <MDBNavbar
        className="p-2 d-print-none"
        style={navStyle}
        double
        expand="md"
        fixed="top"
        // scrolling
      >
        <MDBNavbarNav left>
          <MDBNavItem>
            <div
              onClick={handleToggleClickA}
              key="sideNavToggleA"
              style={{
                lineHeight: '32px',
                marginRight: '1em',
                marginTop: '10px',
                cursor: 'pointer',
              }}
            >
              <MDBIcon icon="bars" color="white" size="2x" />
            </div>
          </MDBNavItem>

          {window.innerWidth > 1300 ? (
            ''
          ) : (
            <MDBNavItem
              className="d-none d-md-flex align-items-center"
              to="/app"
            >
              <Link to="/app">
                <img style={{ width: '120px' }} src={logoHorizontal} />
              </Link>
            </MDBNavItem>
          )}
        </MDBNavbarNav>

        {props.btn && props.btn}
        <MDBNavbarNav right style={specialCaseNavbarStyles}>
          {/* <MDBNavItem>
            <MDBNavLink to="#!" link>
              <MDBIcon
                style={{ fontSize: '12px' }}
                icon="comments"
                className="d-inline-inline"
              />{' '}
              <div style={{ fontSize: '12px' }} className="d-none d-md-inline">
                Suporte
              </div>
            </MDBNavLink>
          </MDBNavItem>
          <MDBNavItem>
            <MDBNavLink to="#!" link>
              <MDBIcon
                style={{ fontSize: '12px' }}
                icon="university"
                className="d-inline-inline"
              />{' '}
              <div style={{ fontSize: '12px' }} className="d-none d-md-inline">
                Tutorial
              </div>
            </MDBNavLink>
          </MDBNavItem> */}
          <MDBNavItem
            onClick={() => setIsOpen(true)}
            className="d-flex align-items-center mr-3 cursor-pointer"
            style={{ position: 'relative' }}
          >
            <MDBIcon far icon="bell" style={{ fontSize: '20px' }} />
            {alertas.length > 0 && (
              <span
                style={{
                  position: 'absolute',
                  top: '30px',
                  right: '20px',
                  transform: 'translate(50%, -50%)',
                  backgroundColor: 'red',
                  color: 'white',
                  borderRadius: '50%',
                  padding: '0.25em 0.5em',
                  fontSize: '0.75em',
                  fontWeight: 'bold',
                }}
              >
                {alertas.length}
              </span>
            )}
          </MDBNavItem>

          <MDBNavItem>
            <MDBDropdown>
              <MDBDropdownToggle nav caret>
                <MDBIcon icon="user" style={{ width: '20px' }} />
              </MDBDropdownToggle>

              <MDBDropdownMenu right>
                <MDBDropdownItem onClick={() => history.push('/meu-perfil')}>
                  Meu Perfil
                </MDBDropdownItem>
                <MDBDropdownItem
                  onClick={() => {
                    sair();
                  }}
                >
                  Sair
                </MDBDropdownItem>
              </MDBDropdownMenu>
            </MDBDropdown>
          </MDBNavItem>
        </MDBNavbarNav>
      </MDBNavbar>
      <main className="d-flex justify-content-center" style={mainStyle}>
        <MDBContainer className="m-0">{props.children}</MDBContainer>
      </main>
      <MDBModal
        overflowScroll
        isOpen={isOpen}
        toggle={() => setIsOpen(false)}
        size="lg"
      >
        <MDBModalHeader
          className=" font-weight-bold"
          toggle={() => setIsOpen(false)}
        >
          Meus Alertas
        </MDBModalHeader>
        <MDBModalBody>
          <MDBContainer>
            {alertas && alertas.length === 0 ? (
              <MDBRow>
                <MDBCol className="green-text h3 text-center">
                  Parabéns você não tem alertas
                </MDBCol>
              </MDBRow>
            ) : (
              <MDBRow
                center
                className="text-danger h5-responsive font-weight-bold mb-5"
              >
                Atenção, os alertas somente serão excluidos quando o sistema
                detectar que o erro foi sanado.
              </MDBRow>
            )}
            {alertas &&
              alertas.length > 0 &&
              alertas.map(alertas => (
                <MDBRow className="p-3 font-weight-bolder border-top ">
                  <MDBCol middle size="12" md="6">
                    {alertas.texto}
                  </MDBCol>
                  {alertas.link && (
                    <MDBCol
                      size="12"
                      md="6"
                      className="d-flex justify-content-center justify-content-md-end "
                    >
                      <Link
                        to={alertas.link}
                        onClick={() => setIsOpen(false)}
                        className="d-flex flex-column align-items-center btn btn-sm font-weight-bold"
                        style={{
                          color: 'rgba(0,0,0,0.6)',
                          maxWidth: '120px',
                        }}
                      >
                        ABRIR
                      </Link>
                    </MDBCol>
                  )}
                </MDBRow>
              ))}
          </MDBContainer>
        </MDBModalBody>
        <MDBModalFooter>
          <MDBBtn color="danger" size="sm" onClick={() => setIsOpen(false)}>
            Fechar
          </MDBBtn>
        </MDBModalFooter>
      </MDBModal>
    </div>
  );
}

import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;

export const Form = styled.form`
  width: 400px;
  padding: 0 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  img {
    /* max-width: 300px; */
    max-width: 250px;
    margin: 10px 0 40px;

    @media (max-width: 500px) {
      max-width: 250px;
    }
  }
  p {
    color: #ff3333;
    margin-bottom: 15px;
    border: 1px solid #ff3333;
    padding: 10px;
    width: 100%;
    text-align: center;
    transition: 2s;
  }
  input {
    flex: 1;
    height: 46px;
    margin-bottom: 15px;
    padding: 10px 20px;
    color: #777;
    font-size: 15px;
    width: 100%;
    border: 1px solid #ddd;
    color: #52658c;
    font-weight: bold;
    border-radius: 5px;
    &::placeholder {
      color: #999;
    }
  }
  button {
    color: #fff;
    font-size: 17px;
    font-weight: 1000;
    background: #52658c;
    padding: 10px;
    border: 0;
    border-radius: 5px;
    width: 100%;
    transition: 0.5s;
    border: 2px solid #52658c;
    cursor: pointer;

    &:hover {
      background: #fff;
      color: #ccc;
      border: 2px solid #666666;
    }
  }
  hr {
    margin: 20px 0;
    border: none;
    border-bottom: 1px solid #cdcdcd;
    width: 100%;
  }
  a {
    font-size: 16;
    font-weight: bold;
    color: #999;
    text-decoration: none;
  }
`;

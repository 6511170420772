import React, { Component } from 'react';
import { MDBSpinner, MDBRow } from 'mdbreact';
import { Link, withRouter } from 'react-router-dom';
import InputMask from 'react-input-mask';
import { toast } from 'react-toastify';

import upperCase from 'upper-case';
import Logo from '../../assets/logofoco.png';
import api from '../../services/api';
import { login, logout, getPerfil, isAuthenticated } from '../../services/auth';
import { version } from '../../../package.json';

import { Form, Container } from './styles';

class SignIn extends Component {
  state = {
    cpf: '',
    password: '',
    error: '',
    spinner: false,
  };

  componentDidMount() {
    isAuthenticated() === true
      ? this?.props?.location?.state?.from?.pathname
        ? this.props.history.push(this.props.location.state.from.pathname)
        : this.props.history.push('/app')
      : logout();
    // logout();
  }

  handleSignIn = async e => {
    e.preventDefault();
    const { cpf, password, rota } = this.state;

    // retira pontos e traços do cpf
    const tratado = cpf.replace(/\D+/g, '');

    if (!cpf || !password) {
      toast.error('Preencha cpf e senha para entrar');
    } else {
      try {
        this.setState({ spinner: true });
        const response = await api.post('/session', { cpf: tratado, password });

        const dados = {
          id: response.data.id,
          cpf: response.data.cpf,
          nome: upperCase(response.data.nome),
          sobrenome: response.data.sobrenome,
          foto: response.data.arquivos,
        };
        this.setState({ spinner: false });
        login(response.data.token, dados);

        // console.log('location', this.props.location.state.from.pathname);
        this?.props?.location?.state?.from?.pathname
          ? this.props.history.push(this.props.location.state.from.pathname)
          : this.props.history.push('/app');
      } catch (error) {
        // this.setState({
        //   error: `Acesso negado !`,
        // });
        toast.error('Acesso Negado!');
        this.setState({ spinner: false });
      }
    }
  };

  render(props) {
    return (
      <>
        <Container>
          <Form onSubmit={this.handleSignIn}>
            <img
              className="w-md-100 w-sm-75"
              src={Logo}
              alt="casa civil logo"
            />
            {this.state.error && <p>{this.state.error}</p>}

            {this.state.spinner ? (
              <div className="d-flex align-items-center m-4">
                <MDBSpinner crazy yellow />
                <div className="ml-3 font-weight-bold">Carregando</div>
              </div>
            ) : (
              <>
                <InputMask
                  type="text"
                  placeholder="CPF"
                  onChange={e => this.setState({ cpf: e.target.value })}
                  mask="999.999.999-99"
                  maskChar=" "
                />
                <input
                  type="password"
                  placeholder="Senha"
                  onChange={e => this.setState({ password: e.target.value })}
                />
              </>
            )}

            <button
              style={{ backgroundColor: 'rgb(0,75,141)' }}
              className="border-0"
              type="submit"
            >
              ENTRAR
            </button>
            <MDBRow
              style={{ fontSize: '11px' }}
              className="font-weight-bolder mt-2"
            >
              Versão: {version}
            </MDBRow>
            {/* <hr /> */}
            {/* <Link to="/signup">Criar conta</Link> */}
          </Form>
        </Container>
      </>
    );
  }
}

export default withRouter(SignIn);
